<template>
  <div class="blog-container">
    <div class="filters">
      <el-select
        v-model="selectedTags"
        multiple
        filterable
        allow-create
        default-first-option
        :reserve-keyword="false"
        :placeholder="$t('blog.articles_filters')"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :value="item.name"
        />
      </el-select>
      <el-button @click="selectedTags = []" class="filter-btn">{{
        $t('blog.reset_filters')
      }}</el-button>
    </div>

    <div v-if="activeTab == 'published' && published" class="articles">
      <div class="article" v-for="article in published" :key="article.url">
        <div class="main-img">
          <img v-if="article.main_img.length > 20" :src="article.main_img" />
        </div>
        <div class="right-side">
          <div class="top">
            <h3 @click.stop="goToArticle(article)">{{ article.title }}</h3>
            <p class="content" v-html="removeMediaTags(article.content)"></p>
          </div>
          <div class="bottom">
            <p>
              {{ $t('blog.published_at') }}
              {{ displayDate(article.created_at) }}
            </p>
            <p v-if="article.updated_at">
              {{ $t('blog.updated_at') }} {{ displayDate(article.updated_at) }}
            </p>
          </div>
          <div class="tags">
            <p v-for="(tag, index) in article.tags" :key="index">#{{ tag }}</p>
          </div>
        </div>
      </div>

      <p v-if="published.length === 0" class="blog-empty">
        {{ $t('blog.empty') }}
      </p>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      activeTab: 'published',
      articles: [],
      value: [],
      selectedTags: [],
      options: [],
    }
  },
  computed: {
    published() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return this.articles
        .filter((article) => {
          const articleDate = new Date(article.created_at)
          const isPublishedToday = articleDate <= today

          const hasSelectedTags =
            this.selectedTags.length === 0 ||
            this.selectedTags.some((tag) => article.tags.includes(tag)) ||
            this.selectedTags.some((tag) =>
              article.content.toLowerCase().includes(tag.toLowerCase())
            )

          return isPublishedToday && hasSelectedTags
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
  },
  mounted() {
    this.fetchArticles()
    this.fetchTags()
  },
  methods: {
    ...mapActions(['selectArticle']),
    async fetchArticles() {
      await axios
        .get(process.env.VUE_APP_BASE_URL + 'api/articles')
        .then((response) => {
          this.articles = response.data.articles
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchTags() {
      await axios
        .get(process.env.VUE_APP_BASE_URL + 'api/tags')
        .then((response) => {
          this.options = response.data.tags
        })
        .catch((error) => {
          console.log(error)
        })
    },
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '')
    },
    goToArticle(article) {
      this.selectArticle(article)
      const titleSlug = this.slugify(article.title)
      const urlSlug = `${titleSlug}`
      this.$router.push({ name: 'article', params: { url: urlSlug } })
    },
    pageArticle(article) {
      this.selectArticle(article)
      this.$router.push(`/blog/${article.url}`)
    },
    handleClose(tag) {
      const index = this.dynamicTags.indexOf(tag)
      if (index >= 0) {
        this.dynamicTags.splice(index, 1)
      }
    },
    showInput() {
      this.inputVisible = true
      nextTick(() => {
        if (this.InputRef) {
          this.InputRef.focus()
        }
      })
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.dynamicTags.push(this.inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    removeMediaTags(content) {
      return content.replace(/<img[^>]*>|<video[^>]*>.*?<\/video>/gs, '')
    },
    displayDate(isoDate) {
      if (!isoDate) return
      const dateObj = new Date(isoDate)
      const day = String(dateObj.getDate()).padStart(2, '0')
      const month = String(dateObj.getMonth() + 1).padStart(2, '0')
      const year = dateObj.getFullYear()
      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style lang="scss" scoped>
.blog-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 2vh 0 5vh 0;

  .filters {
    width: 45vw;
    display: flex;
    justify-content: space-between;

    .filter-btn {
      margin-left: 10px;
    }
  }

  .articles {
    width: 45vw;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 1vh;

    .blog-empty {
      text-align: center;
      margin-top: 15px;
    }

    .article {
      min-height: 10vh;
      padding: 10px;
      text-align: justify;
      border-radius: 5px;
      border: 1px solid rgb(68, 67, 67);
      text-overflow: ellipsis;
      display: flex;

      .main-img {
        max-width: 7vw;
        height: auto;
        margin-right: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 6vw;
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        overflow-wrap: break-word;

        h3 {
          margin-bottom: 1vh;
          color: #b1b1ce;
          cursor: pointer;
          text-align: initial;
        }

        .content {
          display: flex;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          text-overflow: ellipsis;
        }

        .bottom {
          margin-top: 2vh;
          width: 100%;
          display: flex;
          justify-content: space-between;
          color: #b1b1ce;
          font-size: 12px;

          p:nth-of-type(2) {
            font-style: italic;
          }
        }

        .tags {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
          color: #b1b1ce;
          font-weight: bold;
        }

        p {
          min-width: fit-content !important;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .blog-container {
    .filters {
      width: 95%;
    }

    .articles {
      min-width: auto;
      width: 95%;

      .article {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: 0;

        .main-img {
          margin-right: 0;
          padding-top: 15px;

          img {
            max-width: none;
            width: 350px;
            border-radius: 5px;
          }
        }

        .right-side {
          margin-top: 10px;
          width: 95%;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blog-container {
    .articles {
      .article {
        .main-img {
          padding-top: 10px;
          img {
            width: 250px;
          }
        }
      }
    }
  }
}
</style>
